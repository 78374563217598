import axios from './axios_interceptors'
import config from '@/services/config'

export default {

  getMediaKits() {
    return axios.get(`${config.urlCore}/api/v3/media-kits/`,{
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  }, 
  getCategories(categories) {
    return axios.get(`${config.urlCore}/api/v3/media-kits/?${categories}`,{
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  }, 
  
}