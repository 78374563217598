<template>
  <div class="pt-4">
    <b-card>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex mb-2 mb-lg-0">
          <feather-icon icon="ZapIcon" size="40" class="icon-media" />
          <h1 class="ml-2">Media Kits</h1>
        </div>

        <div>
          <b-button
            variant="primary"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse"
            @click="visible = !visible"
            >{{ $t("mediaKits.filters") }}
            <feather-icon icon="ChevronDownIcon" size="16" class="text-white" />
          </b-button>
        </div>
      </div>
    </b-card>

    <b-collapse id="collapse" v-model="visible">
      <b-row>
        <b-col>
          <b-card>
            <div>
              <div>
                <v-select
                  :filter="search"
                  :placeholder="$t('mediaKits.category')"
                  v-model="value"
                  multiple
                  label="text"
                  :options="options"
                >
                  <template #selected-option="{ value }">
                    <span>{{ $t(`mediaKits.${value}`) }} </span>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span></span>
                    {{ $t(`mediaKits.${option.text}`) }}
                  </template>
                </v-select>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-collapse>

    <b-row>
      <b-col
        cols="12"
        class="col-lg-4 col-md-6 h-auto"
        v-for="item in mediaKits"
        :key="item.uuid"
      >
        <b-card class="cardi"
          ><b-card-header class="mb-1">
            <b-card-title class="font-weight-bold text-black">{{
              $t(`mediaKits.${item.category}`)
            }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-title>{{ item.name }}</b-card-title>
            <b-card-text>{{ substr(item.description) }}</b-card-text>
            <a
              :href="downloadButton(item.public_file)"
              target="_blank"
              class="linkMedia"
            >
              <b-button variant="primary" v-if="item.public_file">
                {{ $t("mediaKits.download") }}
              </b-button>
            </a>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import mediaKits_service from "@/services/mediaKits";
import utils from "@/libs/utils";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardText,
  BButton,
  BCardBody,
  BCollapse,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardText,
    BButton,
    BCardBody,
    BCollapse,
    vSelect,
  },
  data() {
    return {
      
      mediaKits: [],
      mediaKitsTotal: [],
      visible: false,
      value: [],
      options: [
        { value: "entertainment", text: "entertainment" },
        { value: "how_to_style", text: "how_to_style" },
        { value: "movies_and_tv", text: "movies_and_tv" },
        { value: "news_politics", text: "news_politics" },
        { value: "nonprofits_activism", text: "nonprofits_activism" },
        { value: "people_blogs", text: "people_blogs" },
        { value: "pets", text: "pets" },
        { value: "sports", text: "sports" },
        { value: "technology_science", text: "technology_science" },
        { value: "travel_tourism", text: "travel_tourism" },
        { value: "video_games", text: "video_games" },
        { value: "accesories_jewelry", text: "accesories_jewelry" },
        { value: "adult_content", text: "adult_content" },
        { value: "alcohol", text: "alcohol" },
        { value: "animals", text: "animals" },
        {
          value: "architecture_urban_design",
          text: "architecture_urban_design",
        },
        { value: "art_artists", text: "art_artists" },
        { value: "beauty", text: "beauty" },
        { value: "business_careers", text: "business_careers" },
        { value: "cars_motorbikes", text: "cars_motorbikes" },
        { value: "cinema_actors_actresses", text: "cinema_actors_actresses" },
        { value: "clothing_outfits", text: "clothing_outfits" },
        { value: "comics_sketches", text: "comics_sketches" },
        { value: "computers_gadgets", text: "computers_gadgets" },
        { value: "diy_design", text: "diy_design" },
        { value: "education", text: "education" },
        {
          value: "extreme_sports_outdoor_activity",
          text: "extreme_sports_outdoor_activity",
        },
        { value: "family", text: "family" },
        { value: "fashion", text: "fashion" },
        { value: "finance_economics", text: "finance_economics" },
        { value: "fitness_gym", text: "fitness_gym" },
        { value: "food_cooking", text: "food_cooking" },
        { value: "health_medicine", text: "health_medicine" },
        { value: "humor_fun_happiness", text: "humor_fun_happiness" },
        { value: "kids_toys", text: "kids_toys" },
        { value: "lifestyle", text: "lifestyle" },
        { value: "literature_journalism", text: "literature_journalism" },
        { value: "luxury", text: "luxury" },
        { value: "machinery_technologies", text: "machinery_technologies" },
        { value: "management_marketing", text: "management_marketing" },
        { value: "mobile_related", text: "mobile_related" },
        { value: "modeling", text: "modeling" },
        { value: "music", text: "music" },
        { value: "nature_landscapes", text: "nature_landscapes" },
        { value: "photography", text: "photography" },
        { value: "racing_sports", text: "racing_sports" },
        { value: "science", text: "science" },
        { value: "shopping_retail", text: "shopping_retail" },
        { value: "shows", text: "shows" },
        { value: "sports_with_ball", text: "sports_with_ball" },
        { value: "sweets_bakery", text: "sweets_bakery" },
        { value: "tobacco_smoking", text: "tobacco_smoking" },
        { value: "trainers_coaches", text: "trainers_coaches" },
        { value: "travel", text: "travel" },
        { value: "water_sports", text: "water_sports" },
        { value: "winter_sports", text: "winter_sports" },
        { value: "other", text: "other" },
      ],
      categoriesSelectedUrl: "",
    };
  },

  watch: {
    value(valueSelected) {
      this.categoriesSelectedUrl = "";
      if (valueSelected.length >= 1) {
        valueSelected.map((val) => {
          this.categoriesSelectedUrl += `category=${val.value}&`;
        });
        mediaKits_service
          .getCategories(this.categoriesSelectedUrl)
          .then((response) => {
            this.mediaKits = response.results;
          });
      } else {
        this.mediaKits = this.mediaKitsTotal;
      }
    },
  },

  beforeCreate() {
    mediaKits_service.getMediaKits().then((response) => {
      this.mediaKits = response.results;
      this.mediaKitsTotal = response.results;
    });
  },

  methods: {
    substr(s) {
      return s.substr(0, 130);
    },
    search(options, search) {
      const vue = this;
      const results = options.filter(function (option) {
        const translate = vue
          .$t(`mediaKits.${option.text}`)
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (
          translate.indexOf(
            search
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) !== -1
        ) {
          return option;
        }
      });
      return results;
    },
    downloadButton(url) {
      return utils.getAssetUrl(url);
    },
  },
};
</script>
<style>
.icon-media {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0;
  padding: 5px;
  margin-right: 10px;
  border-radius: 10px;
}
.cardi {
  height: 260px !important;
}

.cardi:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.linkMedia {
  color: white;
}
.linkMedia:hover {
  color: white;
}
.card-body .social {
  padding: 0px !important;
}
@media (max-width: 360px) {
  .cardi {
    height: 330px !important;
  }
}
</style>